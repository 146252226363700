import React from "react"
import RegisterUser from "../components/Cred/RegisterUser"
import { Container, makeStyles } from "@material-ui/core"
import { useLocation } from "@reach/router"

import Page from "../components/Global/Page"

const Register = props => {
  const classes = useStyles()
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var thisToken = searchParams.getAll("this").toString()
  var thatToken = searchParams.getAll("that").toString()
  console.log(thisToken)
  console.log(thatToken)
  return (
    <Page className={classes.root} title="Register">
      <Container component="main" maxWidth="xs" height="100%">
        <RegisterUser thisToken={thisToken} thatToken={thatToken} />
      </Container>
    </Page>
  )
}

export default Register

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))
