import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Link } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useSiteMetadata } from "../Global/hooks/useSiteMetadata"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function Register({ thisToken, thatToken }) {
  const classes = useStyles()
  const { sbuId } = useSiteMetadata()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [warning, setWarning] = useState("")
  const [handleSubmit, { data, loading }] = useMutation(REGISTER_MUTATION, {
    variables: {
      businessUnitId: sbuId,
      password: password,
      preregId: thisToken,
      preregToken: thatToken,
      email: email,
    },
    onCompleted: data => {
      console.log(data)
    },
  })
  const checkCharacters = () => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/

    if (password1.match(decimal)) {
      setPassword(password1)
      return true
    } else {
      setWarning(
        "Please use a minimum of 8 characters including a number, a capitalized letter, a lowercase letter, and a symbol character like #$%^&*!."
      )
      return false
    }
  }

  const checkPassword = () => {
    if (password1 == "") {
      setWarning("Please enter a password")
      return false
    } else if (password2 == "") {
      setWarning("Please confirm password")
      return false
    } else if (password1 != password2) {
      setWarning("Passwords do not match. Please try again.")
      return false
    } else {
      setPassword(password1)
      setWarning("")
      return true
    }
  }

  const validateForm = () => {
    checkPassword() && checkCharacters() && handleSubmit()
  }

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Register
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={event => {
          event.preventDefault()
          validateForm(event)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              value={lastName}
              onChange={event => setLastName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password1}
              autoComplete="current-password"
              onChange={event => setPassword1(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password2"
              label="Re-enter Password"
              type="password"
              id="password2"
              value={password2}
              autoComplete="current-password"
              onChange={event => setPassword2(event.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Register
        </Button>
        <Grid container justify="flex-end">
          <Grid item>
            <div>{password1 && password1}</div>
          </Grid>
          <Grid item>
            <Link to="/staff" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item>
            {warning.length > 3 && (
              <Alert onClose={() => setWarning("")} severity="warning">
                {warning}
              </Alert>
            )}
            <Box>
              {thisToken}
              <br />
              {thatToken}
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
const REGISTER_MUTATION = gql`
  mutation (
    $preregToken: String!
    $preregId: String!
    $password: String!
    $businessUnitId: Int!
    $email: String!
  ) {
    convertStaffUser(
      preregToken: $preregToken
      preregId: $preregId
      password: $password
      businessUnitId: $businessUnitId
      email: $email
    ) {
      user {
        id
      }
      message
      sbu {
        id
        name
      }
    }
  }
`
